import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, catchError, of } from 'rxjs';

import { SystemService } from '@offconon/core-api';
import { InteractionsService } from '@offconon/core-api';

//import { SearchService } from '@offconon/admin-api';
import * as CatalogStoreActions from './catalog-store.actions';

@Injectable()
export class CatalogStoreEffects {
  private actions$ = inject(Actions);
  private systemService = inject(SystemService);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);
  private interactionsService = inject(InteractionsService);

  loadCatalogStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogStoreActions.loadCatalogStore),
      switchMap((action) => {
        CatalogStoreActions.setLoadingState({ loading: true });
        return this.systemService
          .systemCatalogList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((catalogStore) => {
              return of(
                CatalogStoreActions.loadCatalogStoreSuccess({
                  catalogStore,
                }),
              );
            }),
            catchError((error) => of(CatalogStoreActions.loadCatalogStoreFailure({ error }))),
          );
      }),
    ),
  );
  deleteCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogStoreActions.deleteCatalog),

      switchMap((action) => {
        return this.systemService.systemCatalogDestroy(action.id).pipe(
          switchMap(() => {
            this.messageService.add({
              severity: 'info',
              summary: this.translateService.instant('Delete'),
              detail: this.translateService.instant('The item has been deleted!'),
            });
            return of(
              CatalogStoreActions.deleteCatalogSuccess({
                id: action.id,
              }),
            );
          }),
          catchError((error) => of(CatalogStoreActions.loadCatalogStoreFailure({ error }))),
        );
      }),
    ),
  );

  updateCatalogCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogStoreActions.updateCatalogCollection),
      switchMap((action) => {
        return this.interactionsService
          .interactionsCatalogCollectionCreate(action.interaction)
          .pipe(
            switchMap((catalog) => {
              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('Successful'),
                detail: this.translateService.instant('Your data has been successfully saved.'),
              });
              return of(
                CatalogStoreActions.updateCatalogCollectionSuccess({
                  catalog,
                }),
              );
            }),
            catchError((error) => {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('Error'),
                detail: this.translateService.instant('An error occurred while saving your data.'),
              });
              return of(CatalogStoreActions.loadCatalogStoreFailure({ error }));
            }),
          );
      }),
    ),
  );
}
